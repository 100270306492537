import { ThemeProvider as SuranceThemeProvider } from "@suranceadmin/surance-ui-components";
import LightTheme from "./themes/light.theme";
import Container from "./chat";
// VS: Not using embedded fonts, trying to load from Google CDN
// import { BebasNeue_400Regular } from "@expo-google-fonts/bebas-neue";
// import { PTSans_400Regular, PTSans_700Bold } from "@expo-google-fonts/pt-sans";
// import {
//   PTSansNarrow_400Regular,
//   PTSansNarrow_700Bold,
// } from "@expo-google-fonts/pt-sans-narrow";
import { useFonts } from 'expo-font';
import AppLoading from 'expo-app-loading';

export default function App() {
  const [fontsLoaded] = useFonts({
    // 'BebasNeue-Regular': BebasNeue_400Regular,
    // 'PTSans-Regular': PTSans_400Regular,
    // 'PTSans-Bold': PTSans_700Bold,
    // 'PTSansNarrow-Regular': PTSansNarrow_400Regular,
    // 'PTSansNarrow-Bold': PTSansNarrow_700Bold,

    'BebasNeue-Regular': 'https://fonts.gstatic.com/s/bebasneue/v1/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf',
    'PTSans-Regular': 'https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79P0WOxOGMMDQ.ttf',
    'PTSans-Bold': 'https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf',
    'PTSansNarrow-Regular': 'https://fonts.gstatic.com/s/ptsansnarrow/v11/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf',
    'PTSansNarrow-Bold': 'https://fonts.gstatic.com/s/ptsansnarrow/v11/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf',
  });
  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <SuranceThemeProvider theme={LightTheme}>
         <Container />
      </SuranceThemeProvider>
    );
  }
}