import React, { useState } from "react";
import { TextType, Card, Text } from "@suranceadmin/surance-ui-components";
import styles from "./styles";
import { View, TouchableOpacity, Image } from "react-native";
import ChatbotRobot from "@suranceadmin/icons/web/ChatbotRobot";

const ChatBubbleMe = ({ theme, children }) => (
  <View
    style={[
      styles.chatBubbleMeContainer,
      { backgroundColor: theme.colors.background.default },
    ]}
  >
    {children}
  </View>
);

const ChatBubbleBot = ({ theme, children }) => (
  <View style={styles.flexDirectionRow}>
    <ChatbotRobot
      style={styles.chatBotRobot}
      color={theme.colors.icon.default}
    />
    <Card
      isShadowDisplayed={true}
      style={[
        styles.chatBubbleBotContainer,
        { borderColor: theme.colors.line.light },
      ]}
    >
      {children}
    </Card>
  </View>
);

const Accordion = ({ item }) => {
  // VS: Accordion tap for text to appear
  const { title, subtitle, text } = item;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TouchableOpacity
      onPress={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Text type={TextType.Caption1}>{title}</Text>
      <Text type={TextType.Caption2}>{subtitle}</Text>
      {isOpen && <Text type={TextType.Caption2}>{text}</Text>}
    </TouchableOpacity>
  );
};

const RichContent = ({ item, onClick }) => {
  const { type } = item;
  if (type === "image") {
    const { rawUrl } = item;
    if (rawUrl) {
      return (
        <Image
          source={{ uri: rawUrl }}
          style={[styles.container, { height: 150 }]}
        />
      );
    }
    // VS: If image url is missing - render empty
    return <></>;
  } else if (type === "info") {
    const { title, subtitle } = item;
    return (
      <View>
        <Text type={TextType.Caption1}>{title}</Text>
        <Text type={TextType.Caption2}>{subtitle}</Text>
      </View>
    );
  } else if (type === "accordion") {
    return <Accordion item={item} />;
  } else if (type === "chips") {
    // VS: Ignoring chips - handling them as bottom suggestions.
    return <></>;
    // const { options } = item;
    // return (
    //   <ButtonGroup isVertical>
    //     {options.map((option, index) => {
    //       return (
    //         <Button
    //           key={index}
    //           type={ButtonType.Primary}
    //           onPress={async () => {
    //             console.log("On chip", option);
    //             onClick(option.text);
    //           }}
    //           style={styles.sendButton}
    //         >
    //           {option.text}
    //         </Button>
    //       );
    //     })}
    //   </ButtonGroup>
    // );
  }
  // VS: Unsupported widget
  return <></>;
  // return <Text type={TextType.Body}>{"Unsupported content"}</Text>;
};

const ChatMessageContent = ({ content, onClick }) => {
  if (content.payload) {
    const {
      payload: { richContent },
    } = content;
    // VS: Check if there can be multiple records here
    const richItemArray = richContent[0];
    return (
      <View style={styles.chatRichCard}>
        {richItemArray.map((x, index) => (
          <RichContent item={x} key={index} onClick={onClick} />
        ))}
      </View>
    );
  } else if (content.text) {
    const message = content?.text?.text;
    return (
      <Text style={styles.chatTextContent} type={TextType.Caption1}>
        {message}
      </Text>
    );
  } else {
    return <></>;
  }
};

export const ChatMessage = ({ isMe, theme, content, onClick }) => {
  return (
    <View style={isMe ? styles.chatMessageMe : styles.chatMessageBot}>
      {isMe ? (
        <ChatBubbleMe theme={theme}>
          <ChatMessageContent content={content} onClick={onClick} />
        </ChatBubbleMe>
      ) : (
        <ChatBubbleBot theme={theme}>
          <ChatMessageContent content={content} onClick={onClick} />
        </ChatBubbleBot>
      )}
    </View>
  );
};

export const RenderMessage = ({ item, index, onClick, theme }) => {
  const { from, content } = item;
  return (
    <View style={index === 0 ? styles.renderMessageMargin : null}>
      <ChatMessage
        content={content}
        isMe={from === "me"}
        theme={theme}
        onClick={onClick}
      />
    </View>
  );
};
