const Colors = {
  background: {
    white: '#FFFFFF',
    default: '#F0F0F0',
    header: '#012230',
    headerModal: '#052E40',
    important: '#F9EEE2',
    warning: '#CB3E5A',
  },
  button: {
    primary: '#01B0C1',
    secondary: '#FFFFFF',
    negative: '#CB3E5A',
    disabled: '#DDE2E8',
    secondaryBorder: '#01B0C1',
    transparentBorder: '#FFFFFF',
  },
  text: {
    default: '#052E40',
    light: '#546B84',
    ondark: '#FFFFFF',
    disabled: '#93A3B6',
    link: '#01B0C1',
    warning: '#CB3E5A',
    buttonSecondary: '#052E40',
  },
  line: {
    default: '#93A3B6',
    light: '#DDE2E8',
  },
  level: {
    unknown: '#93A3B6',
    notSafe: '#CB3E5A',
    mildlySafe: '#DD8206',
    fullySafe: '#3E78C7',
  },
  notification: {
    critical: '#CB3E5A',
    important: '#DD8206',
    improvement: '#E6AF0B',
  },
  icon: {
    default: '#052E40',
    link: '#01B0C1',
    ondark: '#FFFFFF',
    warning: '#CB3E5A',
    critical: '#CB3E5A',
    important: '#DB7B2B',
    improvement: '#E6AF0B',
    valid: '#01B0C1',
  },
};
const Fonts = {
  headline1: {
    fontFamily: 'BebasNeue-Regular',
    fontSize: 42,
    lineHeight: 42,
  },
  headline2: {
    fontFamily: 'BebasNeue-Regular',
    fontSize: 32,
    lineHeight: 32,
  },
  subtitle: {
    fontFamily: 'PTSansNarrow-Regular',
    fontSize: 24,
    lineHeight: 28,
  },
  subtitleBold: {
    fontFamily: 'PTSansNarrow-Bold',
    fontSize: 24,
    lineHeight: 28,
  },
  body: {
    fontFamily: 'PTSans-Regular',
    fontSize: 18,
    lineHeight: 24,
  },
  bodyBold: {
    fontFamily: 'PTSans-Bold',
    fontSize: 18,
    lineHeight: 24,
  },
  bodyWide: {
    fontFamily: 'PTSans-Regular',
    fontSize: 18,
    lineHeight: 28,
  },
  buttonPrimary: {
    fontFamily: 'BebasNeue-Regular',
    fontSize: 18,
    lineHeight: 16,
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginBottom: -3.5,
    marginTop: -0.5,
  },
  buttonSecondary: {
    fontFamily: 'PTSansNarrow-Regular',
    fontSize: 16,
    lineHeight: 16,
  },
  overline: {
    fontFamily: 'PTSans-Regular',
    fontSize: 16,
    lineHeight: 16,
    textTransform: 'uppercase',
  },
  overlineBold: {
    fontFamily: 'PTSans-Bold',
    fontSize: 16,
    lineHeight: 16,
    textTransform: 'uppercase',
  },
  caption1: {
    fontFamily: 'PTSans-Regular',
    fontSize: 16,
    lineHeight: 20,
  },
  caption1Bold: {
    fontFamily: 'PTSans-Bold',
    fontSize: 16,
    lineHeight: 20,
  },
  caption2: {
    fontFamily: 'PTSans-Regular',
    fontSize: 14,
    lineHeight: 16,
  },
  caption2Bold: {
    fontFamily: 'PTSans-Bold',
    fontSize: 14,
    lineHeight: 16,
  },
  caption3: {
    fontFamily: 'PTSans-Regular',
    fontSize: 12,
    lineHeight: 16,
  },
  caption3Bold: {
    fontFamily: 'PTSans-Bold',
    fontSize: 12,
    lineHeight: 16,
  },
};

const Theme = {
  colors: Colors,
  fonts: Fonts,
  isRTL: true,
};

export default Theme;
