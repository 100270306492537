import React from "react";
import {
  useTheme,
  Button,
  ButtonType,
  TextType,
  Text,
} from "@suranceadmin/surance-ui-components";
import styles from "./styles";
import { View, ActivityIndicator } from "react-native";

export enum InitializationState {
  Initializing,
  Initialized,
  Failed,
  RestartFailed,
}

export const EmptyView = ({ initState, onRetry }) => {
  const theme = useTheme();
  const showRetryButton =
    initState !== InitializationState.Initialized &&
    initState !== InitializationState.Initializing;
  return (
    <View style={styles.emptyComponent}>
      {!showRetryButton && (
        <ActivityIndicator size="large" color={theme.colors.text.light} />
      )}
      {initState === InitializationState.Initialized && (
        <Text type={TextType.Body}>{"This chat is empty"}</Text>
      )}
      {initState === InitializationState.Failed && (
        <Text type={TextType.Body}>
          {"Failed to connect to the chat. Please retry."}
        </Text>
      )}
      {initState === InitializationState.RestartFailed && (
        <Text type={TextType.Body}>
          {"Failed to restart the chat. Please retry."}
        </Text>
      )}
      {initState === InitializationState.Initializing && (
        <Text type={TextType.Body}>{"Connecting..."}</Text>
      )}
      {showRetryButton && (
        <Button
          type={ButtonType.Primary}
          onPress={onRetry}
          style={styles.retryButton}
        >
          {"Retry"}
        </Button>
      )}
    </View>
  );
};
