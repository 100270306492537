import axios from "axios";
const baseUrl = "https://us-central1-surance-test.cloudfunctions.net/pepper-test";

export async function startChat(chatId: string, environment: string) {
  console.log("Will initialize chat");
  const response = await axios.post(`${baseUrl}/api/chat/start`, {
    environment: environment,
    // VS: Currently only one chat per user is supported
    sessionId: chatId,
    userId: chatId,
  });
  const responseData = response.data;
  console.log("Response", responseData);
  return responseData;
}

export async function sendQuery(chatId: string, environment: string, messageObject: any) {
  const text = messageObject.content.text.text[0];
  console.log("Will send message", text);
  const response = await axios.post(`${baseUrl}/api/chat/send`, {
    message: messageObject,
    environment: environment,
    // VS: Currently only one chat per user is supported
    sessionId: chatId,
    userId: chatId,
  });
  const responseData = response.data;
  console.log("Response", responseData);
  return responseData;
}

export async function restartChat(chatId: string, environment: string) {
  console.log("Will restart chat");
  const response = await axios.post(`${baseUrl}/api/chat/restart`, {
    environment: environment,
    // VS: Currently only one chat per user is supported
    sessionId: chatId,
    userId: chatId,
  });
  const responseData = response.data;
  console.log("Response", responseData);
  return responseData;
}