import { v4 } from "uuid";

export function createMessageObject(messageToSend) {
  return {
    id: `me_${v4()}`,
    from: "me",
    content: {
      // VS: This is the format used by DF internally, using the same one to avoid converting
      text: { text: [messageToSend] },
    },
    timestamp: new Date().getTime(),
  };
}

export const getSuggestions = (lastMessage) => {
  if (lastMessage?.content?.payload) {
    const {
      payload: { richContent },
    } = lastMessage.content;
    // VS: Check if there can be multiple records here
    const richItemArray = richContent[0];
    const chipsObject = richItemArray.find((x) => x.type === "chips");
    if (chipsObject) {
      const { options } = chipsObject;
      return options.map((x) => x.text);
    }
  }
  return [];
};

export const getChatId = () => {
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const parameterChatId = urlParams.get("id");
  if (parameterChatId) {
    console.log(`Will use chatId from parameter ${parameterChatId}`);
    localStorage.setItem("chat-id", parameterChatId);
    return parameterChatId;
  }
  const storedChatId = localStorage.getItem("chat-id");
  if (storedChatId) {
    console.log(`Existing identifier loaded ${storedChatId}`);
    return storedChatId;
  } else {
    const newIdentifier = v4();
    console.log(`New identifier generated ${newIdentifier}`);
    localStorage.setItem("chat-id", newIdentifier);
    return newIdentifier;
  }
};
