import React from "react";
import { TouchableOpacity, Modal } from "react-native";
import { TextType, Text } from "@suranceadmin/surance-ui-components";
import {
  MenuProvider,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { ChatbotRestart, ChatbotPic } from "@suranceadmin/icons/native";
import styles from "./styles";

export const MenuList = ({
  theme,
  visible,
  menuToggle,
  onRestart,
  onOpenImagePicker,
}) => {
  return (
    <Modal animationType="fade" transparent={true} visible={visible}>
      <MenuProvider skipInstanceCheck={true}>
        <TouchableOpacity
          style={styles.container}
          activeOpacity={1}
          onPress={menuToggle}
        />
        <Menu>
          <MenuTrigger>
            <TouchableOpacity activeOpacity={1} onPress={menuToggle}>
              <MenuOptions
                customStyles={{
                  optionsWrapper: [
                    styles.menuContainer,
                    {
                      backgroundColor: theme.colors.background.white,
                      borderColor: theme.colors.line.light,
                      shadowColor: theme.colors.text.light,
                    },
                  ],
                }}
              >
                {onOpenImagePicker && (
                  <MenuOption
                    style={styles.menuFirstItem}
                    onSelect={() => {
                      menuToggle();
                      setTimeout(onOpenImagePicker, 200);
                    }}
                  >
                    <ChatbotPic
                      style={styles.menuItemIconMargin}
                      color={theme.colors.icon.link}
                    />
                    <Text
                      style={styles.menuItemTextMargin}
                      type={TextType.Caption1}
                      color={theme.colors.text.link}
                    >
                      {"Add picture"}
                    </Text>
                  </MenuOption>
                )}

                {onRestart && (
                  <MenuOption
                    style={styles.menuLastItem}
                    onSelect={() => {
                      menuToggle();
                      onRestart();
                    }}
                  >
                    <ChatbotRestart
                      style={styles.menuItemIconMargin}
                      color={theme.colors.icon.link}
                    />
                    <Text
                      style={styles.menuItemTextMargin}
                      type={TextType.Caption1}
                      color={theme.colors.text.link}
                    >
                      {"Restart chat"}
                    </Text>
                  </MenuOption>
                )}
              </MenuOptions>
            </TouchableOpacity>
          </MenuTrigger>
          <MenuOptions>
            <MenuOption />
          </MenuOptions>
        </Menu>
      </MenuProvider>
    </Modal>
  );
};
